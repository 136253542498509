import React from 'react';
import { withRouter } from 'react-router-dom';


//ClassHanzi
class ClassHanzi extends React.Component {
	render(){
		let data = this.props.data,
			hanzi = data.hanzi
			console.log("ClassHanzi///", data)

		let hanziItem = hanzi.map((item, index) =>
			<span key={index}>
				{item}
			</span>
		)
		return(
			<span>{hanziItem}</span>
		)
	}
}


//ClassForm
class ClassForm extends React.Component {
	render(){
		let data = this.props.data
			console.log("ClassForm///", data)

		let formItem = data.map((item, index) =>
			<div className="hanziItem" key={index} index={index} onClick={this.props.onHanzi.bind(this, item.writh)}>
				{(index + 1) + ". "}
				{ <ClassHanzi data={item.writh} /> }
			</div>
		)
		
		return(
			<div className="class_form">
				{formItem}
			</div>
		)
	}
}


//classItem
class ClassCata extends React.Component {
	render(){
		let data = this.props.data
			console.log("ClassCata///", data.length, data)
		
		let cataItem = data.map((item, index) =>
			<div className="item" key={index}>
				<div className="item_ClassName">{item.ClassName}</div>
				<ClassForm data={item.form} onHanzi={this.props.onHanzi} />
			</div>
		)
		
		return(
			<div className="class_catalog">
				{cataItem}
			</div>
		)
	}
	
}



/**
 * 路由整合/父组件
 */
class HanziClass extends React.Component {

	//页面状态数据（类似this.data）
	constructor(props) {
		super(props);
		this.state = {
			hanzi_data: {}
		}
		
		// 这边绑定是必要的，这样 `this` 才能在回调函数中使用
		//this.onHanzi = this.onHanzi.bind(this);
		
	}


	//页面初始化
	componentDidMount() {
		//获取汉字
		this.getHanzi("d81cd5415f6c57d50059b7dc5bc51b70")
    
	}

	//获取汉字
	getHanzi(_id){
		let that = this
		//TCB云数据库查询
		var coll = global.tcb.app.database().collection("Hanzi_class")
			coll.doc(_id)
			.get()
			.then((res) => {
				console.log('getHanzi//then:', res)

				if(res.data.length){
					console.log('getHanzi//res.data:', res.data[0])
					that.setState({
						hanzi_data: res.data[0]
					})
				}
			})
  			.catch((err) => {
				console.log('getHanzi//catch:', err)
			});
	}

	//点击事件
	onHanzi(event){
		console.log("onHanzi///", event)
		this.props.history.push({
			pathname: '/ToHanzi',
			state: {
				...event
			}
		})
	}


	render() {
		return (
			<div className="HanziClass">
				<div className="class_header flex-center">
					<div className="class_name">{this.state.hanzi_data.class}</div>
					<div className="class_tips">{this.state.hanzi_data.class_tips}</div>
				</div>

				{this.state.hanzi_data.class_hanzi ? <ClassCata data={this.state.hanzi_data.class_hanzi} onHanzi={this.onHanzi.bind(this)} /> : null}
			</div>
		);
	}
   
}

export default withRouter(HanziClass);