import React from 'react';
import { withRouter } from 'react-router-dom';
import HanziWriter from 'hanzi-writer';


/**weui */
import { 
	ButtonArea,
    Button
} from 'react-weui';


/**
 * 首页的子组件
 */
class ToHanzi extends React.Component {

	//页面状态数据（类似this.data）
	constructor(props) {
		super(props);
		this.state = {
			zi: props.data
		}
		
		
		// 这边绑定是必要的，这样 `this` 才能在回调函数中使用
		this.animate = this.animate.bind(this);
		this.quiz = this.quiz.bind(this);
	}
  


	//页面初始化
	componentDidMount() {
		//页面尺寸信息
		let screen = window.screen

		//接受到的汉字数据
		let { hanzi, pinyin } = this.props.location.state
		console.log("接受到的汉字数据//", hanzi)

		this.setState({
			zi: {
				hanzi,
				pinyin,
				leng: hanzi.length
			},
			num: 0,
			pinyin: null
		})

		//汉字初始化
		this.hanzi(screen, hanzi)
		this.setState({ pinyin: pinyin[0] })
	}


	//下一个汉字
	nextHanzi(){
		let {leng, hanzi, pinyin} = this.state.zi,
			num = this.state.num;
			num = num + 1

		console.log("num", num, leng)
		if(num < leng){
			this.setState({num})
			this.characterHanzi(hanzi[num], pinyin[num])
		}
	}

	//上一个汉字
	preHanzi(){
		let {leng, hanzi, pinyin} = this.state.zi,
			num = this.state.num;
			
		console.log("num", num, leng)
		if(num > 0){
			num = num - 1
			this.setState({num})
			this.characterHanzi(hanzi[num], pinyin[num])
		}
	}


	//切换汉字
	characterHanzi(hanzi,pinyin){
		this.writer.setCharacter(hanzi)
		this.setState({ pinyin })
	}
  

	//汉字初始化
	hanzi(screen,hanzi){
		let zi = hanzi[0]
		this.writer = HanziWriter.create('target', zi, {
			  width: screen.availWidth,
			  height: screen.availWidth,
			  padding: 5,
			  //radicalColor: '#168F16', // 部手颜色
			  showOutline: true, //文字灰色阴影
			  showHintAfterMisses: 1, //出错次数提示
			  drawingWidth: 10,
		});	
	}
  
  
  
	//播放动画
	animate(){
		this.writer.animateCharacter();
	}


	//手写测试
	quiz(){
		let stroke = {
			  //错误时
			  onMistake: function(strokeData) {
				console.log("onMistake", strokeData);
			  },
			  //正确时
			  onCorrectStroke: function(strokeData) {
				console.log('onCorrectStroke', strokeData);
			  },
			  //完成时
			  onComplete: function(summaryData) {
				console.log('onComplete', summaryData);
			  }
			}

		this.writer.quiz(stroke);
	}
  
  
	render() {
		return (
		<div className="setHanzi">
			<div className="hanziBody">
				<div className="pinyin">{this.state.pinyin}</div>
				<div id="target"></div>
			</div>
			

			<ButtonArea>
			<Button onClick={this.animate} size="small">播放动画</Button>
			<Button onClick={this.quiz} size="small" type="warn">手写测试</Button>
			</ButtonArea>

			<div>
				<ButtonArea direction="horizontal">
					<Button className="addClassInput" onClick={this.preHanzi.bind(this)}>上一个</Button>
					<Button onClick={this.nextHanzi.bind(this)}>下一个</Button>
				</ButtonArea>
			</div>

		</div>
		);
	}
   
}

export default withRouter(ToHanzi);