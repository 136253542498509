import cloudbase from "@cloudbase/js-sdk";

//初始化
let app = cloudbase.init({
    env: "student-5gwdkqww6091e1f9"
});

// 匿名登录
app.auth({
    persistence: "local"
})
.anonymousAuthProvider()
.signIn()
.then((res) => {
    console.log("TCB登录成功", res.isAnonymousAuth)
    // 登录成功
})
.catch((err) => {
    // 登录失败
    console.log("TCB登录失败", err)
});


global.tcb = {
    app: app,
    name: {
        app: "hanzi_tcb_demo",
        version: "v1.0",
        dev: "xiaolei"
    },
};