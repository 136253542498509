import React from 'react';

import { 
	ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
	Page,
	Toast,
	Toptips,
} from 'react-weui';

export default class UpdateClass extends React.Component {
	//页面状态数据（类似this.data）
	constructor(props) {
		super(props);
		this.state = {
			warnTimer: null,
			showWarn: false,
			toptips: null,

			loadingText:null,
			showLoading: false,
			form: {
				class: '',
				class_tips: '',
				class_text_number: '',
				class_id: ''
			}
		}
		
		// 这边绑定是必要的，这样 `this` 才能在回调函数中使用
		this.UpdateClass = this.UpdateClass.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
    
    //页面初始化
	componentDidMount() {

		console.log(this.props.name)

		/**
		 * 获取数据库实例
		 * const db = app.database();
		 * 获取集合的引用
		 * const collection = db.collection("user");
		 * 查询指令
		 * collection.where({ _openid: myOpenID });
		 */
		/*直接向数据库写入数据
		var coll = this.props.app.database().collection("Hanzi_class")

			coll.add({
				name: 1,
				age: 2,
				avatar: 3
			})
			.then((res) => {
				console.log("直接向数据库写入数据", res)
			})
			.catch((err) => {
				console.log("直接向数据库写入数据", err)
			})
		*/

    }



	componentWillUnmount() {
        this.state.warnTimer && clearTimeout(this.state.warnTimer);
        this.state.successTimer && clearTimeout(this.state.successTimer);
        this.state.infoTimer && clearTimeout(this.state.infoTimer);
    }
  
	
	//添加年级 =1
	UpdateClass(e){
		let that = this
		let form = this.state.form

		if(!form.class || !form.class_tips || !form.class_text_number || !form.class_id){
			this.setState({
				showWarn: true,
				toptips: '请填写完整',
			})
			this.state.warnTimer = setTimeout(()=> {
				this.setState({showWarn: false});
			}, 2000);

			return false
		}

        that.setState({showLoading: true, loadingText: '正在添加'})
        

		//TCB云数据库
		var coll = this.props.app.database().collection("Hanzi_class")
			coll.add(form)
			.then((res) => {
				console.log("TCB云数据库/Hanzi_class/", res)
				that.setState({showLoading: false})

				if(res.id){
					console.log('添加成功:', res.id)
					//清空输入框
					let form = {
						class: '',
						class_tips: '',
						class_text_number: '',
						class_id: ''
					}
					that.setState({form})
				}

			})
			.catch((err) => {
				console.log("TCB云数据库/Hanzi_class/", err)
			})

        

        /*
		let url = 'https://pay.doubia.cn/hanzi_UpdateClass',
			data = form

		post(url, data).then((res)=>{

			that.setState({showLoading: false})
			console.log(res)

			if(res.code == 200){
				console.log('添加成功:', res.msg)
				//清空输入框
				let form = {
					class: '',
					class_tips: '',
					class_text_number: '',
					class_id: ''
				}
				that.setState({form})
			}
		}).catch((err)=>{
			console.log(err)
        })
        */

	}

	//触发事件
	handleChange(event) {
		const target = event.target;
		const name = target.name;
		console.log(name, target.value)
		
		let form = this.state.form
			form[name] = target.value
		this.setState({
			form
		})

		console.log(this.state.form)
	}
  

	render() {
		return (
			<div>
				<Page className="input" title="Input" subTitle="表单输入">
					<CellsTitle>年级信息</CellsTitle>
					<Form>
						<FormCell>
							<CellHeader>
								<Label>年级</Label>
							</CellHeader>
							<CellBody>
								<Input type="text" placeholder="请输入年级" value={this.state.form.class} name="class" onChange={this.handleChange} />
							</CellBody>
						</FormCell>
						<FormCell>
							<CellHeader>
								<Label>提示</Label>
							</CellHeader>
							<CellBody>
								<Input type="text" placeholder="请输入提示" value={this.state.form.class_tips} name="class_tips" onChange={this.handleChange} />
							</CellBody>
						</FormCell>
						<FormCell>
							<CellHeader>
								<Label>字数</Label>
							</CellHeader>
							<CellBody>
								<Input type="number" placeholder="请输入汉字字数" value={this.state.form.class_text_number} name="class_text_number" onChange={this.handleChange} />
							</CellBody>
						</FormCell>
						<FormCell>
							<CellHeader>
								<Label>ID</Label>
							</CellHeader>
							<CellBody>
								<Input type="number" placeholder="请输入年级ID" value={this.state.form.class_id} name="class_id" onChange={this.handleChange} />
							</CellBody>
						</FormCell>
					</Form>

					<ButtonArea>
						<Button onClick={this.UpdateClass}>添加年级</Button>
					</ButtonArea>

					<Toptips type="warn" show={this.state.showWarn}>{this.state.toptips}</Toptips>
					<Toast icon="loading" show={this.state.showLoading}>{this.state.loadingText}</Toast>
					
				</Page>
			</div>
		);
	}    
}