import React from 'react';

import { 
	ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    Agreement,
	Page,
	Toptips,
	Dialog
} from 'react-weui';


//选择年级
class SelectClassID extends React.Component {
	render() {
		return(
			<Form>
			<FormCell select selectPos="after">
				<CellHeader>
					<Label>选择年级</Label>
				</CellHeader>
				<CellBody>
					<Select data={this.props.data} onChange={this.props.Change} />
				</CellBody>
			</FormCell>
			</Form>
		)
	}
}


//循环添加生字表
class ForFormClass extends React.Component{
	render(){
		const forItem = this.props.data.map((item, index) =>
			<Form key={index}>
				<CellsTitle>序列：{index} / {index + 1}行生字</CellsTitle>
				<FormCell>
					<CellHeader>
						<Label>生字</Label>
					</CellHeader>
					<CellBody>
						<TextArea placeholder="格式：bà,mā,wǒ|爸,妈,我" rows="3" length="200" name={index} onClick={this.props.ClickTextArea} value={JSON.stringify(this.props.form[index].writh)} showCounter={false}></TextArea>
					</CellBody>
				</FormCell>
				<FormCell>
					<CellHeader>
						<Label>课文照片</Label>
					</CellHeader>
					<CellBody>
						<Input type="text" placeholder="请上传课文照片" name={index} onChange={this.props.ChangePhoto} />
					</CellBody>
				</FormCell>
			</Form>
		)

		return(
			<div>
				{forItem}
			</div>
			
		)
	}

}



//设置汉字输入框
class SetHanziBox extends React.Component{
	render(){
		return(
			<CellBody>
				<TextArea placeholder="格式：bà,mā,wǒ|爸,妈,我" rows="3" length="200" onChange={this.props.WriteHanziBox} value={this.props.WriteHanziBoxValue}></TextArea>
			</CellBody>
		)
	}
}



export default class UpdateForm extends React.Component {

	//页面状态数据（类似this.data）
	constructor(props) {
		super(props);
		this.state = {
			showToptips: false,
			toptips: null,

			showIOS2: false,
			style2: {
				title: 'Heading',
				buttons: [
					{
						type: 'default',
						label: '取消',
						onClick: this.hideDialog.bind(this)
					},
					{
						type: 'primary',
						label: '确定',
						onClick: this.hideDialog.bind(this)
					}
				]
			},
			SetHanziBox: false,
			WriteHanziBox: "",

			form: [{
				writh: {},
				photo: ""
			}],

			
			//选中的年级ID
			QueryClass: [],
			cla_id: null,
			ClassName: '',
		}
		
		// 这边绑定是必要的，这样 `this` 才能在回调函数中使用
		this.UpdateForm = this.UpdateForm.bind(this);
		this.addClassInput = this.addClassInput.bind(this);
		this.subClassInput = this.subClassInput.bind(this);
		this.SelectChange = this.SelectChange.bind(this);
		this.ClassNameChange = this.ClassNameChange.bind(this);
		this.ChangePhoto = this.ChangePhoto.bind(this);
		this.ClickTextArea = this.ClickTextArea.bind(this);
		this.WriteHanziBox = this.WriteHanziBox.bind(this);

	}


	//输入生字表类名
	ClassNameChange(e){
		console.log("ClassNameChange//", e.target.value)
		this.setState({
			ClassName: e.target.value
		})
	}

	//上传图片
	ChangePhoto(e){
		let that = this
		const target = e.target;
		let name = target.name
		let value = target.value
		console.log("ChangePhoto//", name, value)
		let form = this.state.form
			form[name].photo = value
		
		this.setState({
			form
		})

		console.log("form", this.state.form)
	}

	//点击生字框
	ClickTextArea(e){
		console.log("ClickTextArea", e.target.name)
		
		let style2 = this.state.style2
			style2.title = "输入生字表"
			style2.index = e.target.name
			style2.buttons[1].onClick = this.SetHanziArray.bind(this)
		this.setState({
			showIOS2: true,
			SetHanziBox: true,
			style2
		})
	}

	//隐藏生字框
	hideDialog() {
        this.setState({
			showIOS2: false,
			WriteHanziBox: ""
        });
	}

	//设置转换生字表为数组
	SetHanziArray(e){
		let that = this,
			index = this.state.style2.index,
			WriteHanziBox = this.state.WriteHanziBox;
			console.log("SetHanziArray", index, WriteHanziBox)


		//判断格式
		if(!WriteHanziBox.length || WriteHanziBox.split("|").length < 2){
			return null
		}
		
		this.GetHanziObject(WriteHanziBox, (res)=>{
			console.log(res)

			let form = that.state.form
				form[index].writh = res
			that.setState({
				form
			})
		})

		this.hideDialog()
		console.log("form", this.state.form)
	}


	//输入生字表数组
	WriteHanziBox(e){
		console.log("WriteHanziBox", e.target.value)
		this.setState({
			WriteHanziBox: e.target.value
		})
	}

	//转换汉字字符串为对象格式
	GetHanziObject(str, ret){
		let strs = str.split("|");
		let piny = strs[0].split(","),
			hanz = strs[1].split(",");

		ret({
			pinyin: piny, 
			hanzi: hanz
		})
	}
	

	//增加生字表
	addClassInput(){
		let form = this.state.form
		let classArr = {
			writh: {},
			photo: ""
		}
		form.push(classArr)
		this.setState({
			form
		})
	}

	//减少生字表
	subClassInput(){
		let form = this.state.form
		form.pop()
		this.setState({
			form
		})
	}


	
	//dom初始化
	componentDidMount() {
		let QueryClass = this.props.QueryClass
		    console.log("UpdateForm_QueryClass///", QueryClass[0])
		let value = JSON.parse(QueryClass[0].value)

		//设置默认选定
		this.setState({
			cla_id: value.cla_id,
			QueryClass
		})
  	}
  
	
	//选择Select
	SelectChange(e){
		let select = e.target.value
			console.log("SelectChange//", select)
		let value = JSON.parse(select)

		this.setState({
			cla_id: value.cla_id
		})
	}

	
	//添加生字表
	UpdateForm(){
		let {cla_id, ClassName, form} = this.state,
			that = this

		if(!ClassName.length){
			return null
		}
		

		const db = global.tcb.app.database()
        const comm = db.command
        const coll = db.collection("Hanzi_class")
            coll.doc(cla_id)
            .update({
                class_hanzi: comm.push({
					ClassName,
					form
				})
            })
            .then((res) => {
				console.log('添加生字表/TCB数据库:', res)
				that.setState({
					form: [{
						writh: {},
						photo: ""
					}]
				})
            })
            .catch((e) => {
                console.log('添加生字表/TCB数据库:', e)
            });
	}


	render() {
		return (
			<div>
				<Page className="input" title="Input" subTitle="表单输入">
					<CellsTitle>选择年级</CellsTitle>
					<SelectClassID data={this.state.QueryClass} Change={this.SelectChange}></SelectClassID>

					<CellsTitle>生字表</CellsTitle>
					<Form>
						<FormCell>
							<CellHeader>
								<Label>类名</Label>
							</CellHeader>
							<CellBody>
								<Input type="text" placeholder="请输入类名" onChange={this.ClassNameChange}/>
							</CellBody>
						</FormCell>
					</Form>

					<ButtonArea>
						<Button type="default" size="small" className="addClassInput" onClick={this.addClassInput}>+</Button>
						<Button type="warn" size="small" onClick={this.subClassInput}>-</Button>
					</ButtonArea>

					<ForFormClass data={this.state.form} ChangePhoto={this.ChangePhoto} ClickTextArea={this.ClickTextArea} form={this.state.form}></ForFormClass>

					<ButtonArea>
						<Button onClick={this.UpdateForm}>添加生字表</Button>
					</ButtonArea>

					<Toptips type="warn" show={this.state.showToptips}>{this.state.toptips}</Toptips>
					<Dialog type="ios" title={this.state.style2.title} buttons={this.state.style2.buttons} show={this.state.showIOS2}>
						{ this.state.SetHanziBox? ( <SetHanziBox WriteHanziBoxValue={this.state.WriteHanziBox} WriteHanziBox={this.WriteHanziBox} /> ) : ( "This is iOS Style 2" ) }
                	</Dialog>

				</Page>
			</div>
		);
	}
   
}