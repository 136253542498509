import React from 'react';

import App from './App';
import SetHanzi from './SetHanzi';

import {
    Tab,
    TabBody,
    TabBar,
    TabBarItem,
    TabBarIcon,
    TabBarLabel,
    Article,

} from 'react-weui';

import IconButton from './home/images/icon_1.png';
import IconMsg from './home/images/icon_2.png';
import IconArticle from './home/images/icon_3.png';
import IconCell from './home/images/icon_4.png';


export default class TabBarDemo extends React.Component {
    state= {
        tab : 0
    };


    //页面初始化
	componentDidMount() {

    }


    render() {
        return (
            <Tab>
                <TabBody>
                    <div style={{display: this.state.tab === 0 ? null : 'none'}}>
                        {global.tcb.app ? <App /> : null}
                    </div>

                    <div className="root_page" style={{display: this.state.tab === 1 ? null : 'none'}}>
                        {global.tcb.app ? <SetHanzi /> : null}
                    </div>
                    
                    <Article style={{display: this.state.tab === 2 ? null : 'none'}}>
                        <h1>Page 3</h1>
                    </Article>

                    <Article style={{display: this.state.tab === 3 ? null : 'none'}}>
                        <h1>Page 4</h1>
                       
                    </Article>
                </TabBody>

                
                <TabBar>
                    <TabBarItem
                        active={this.state.tab === 0}
                        onClick={e=>this.setState({tab:0})}
                        icon={<img src={IconButton}/>}
                        label="汉字"
                    />
                    <TabBarItem active={this.state.tab === 1} onClick={e=>this.setState({tab:1})}>
                        <TabBarIcon>
                            <img src={IconMsg}/>
                        </TabBarIcon>
                        <TabBarLabel>上传</TabBarLabel>
                    </TabBarItem>
                    <TabBarItem
                        active={this.state.tab === 2}
                        onClick={e=>this.setState({tab:2})}
                        icon={<img src={IconArticle}/>}
                        label="Tab3"
                    />
                    <TabBarItem
                        active={this.state.tab === 3}
                        onClick={e=>this.setState({tab:3})}
                        icon={<img src={IconCell}/>}
                        label="Tab4"
                    />
                </TabBar>
            </Tab>
        );
    }
};