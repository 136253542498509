import React from 'react';

import { 
	ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    Agreement,
	Page,

    Tab,
    TabBody,
    NavBar,
    NavBarItem,
	Article,
	Toast,
	Toptips,

	Dialog,

	Gallery, 
	GalleryDelete, 
	Uploader,
} from 'react-weui';




//选择年级
class SelectClassID extends React.Component {
	render() {
		return(
			<Form>
			<FormCell select selectPos="after">
				<CellHeader>
					<Label>选择年级</Label>
				</CellHeader>
				<CellBody>
					<Select data={this.props.data} onChange={this.props.Change} />
				</CellBody>
			</FormCell>
			</Form>
		)
	}
}




/**
 * 上传课文图片
 */

export default class UpdatePhoto extends React.Component {

	//页面状态数据（类似this.data）
	constructor(props) {
		super(props);

        this.OnUpdatePhoto = this.OnUpdatePhoto.bind(this);
        this.SelectChange = this.SelectChange.bind(this);

		this.state = {
            gallery: false,
            demoFiles : [
				/*{
                    url: "",
                    status: '50%'
				}
				*/
            ],


            //选中的年级ID
            QueryClass: [],
			cla_id: null,
            class_id: null,
        }
	}

	//将base64转换为文件
	dataURLtoFile(dataurl, filename) { 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
		u8arr = new Uint8Array(n);
		
        while (n--) {
        	u8arr[n] = bstr.charCodeAt(n);
        }
      	return new File([u8arr], filename, {
     		type: mime
    	});
    }
    

    
    //页面初始化
	componentDidMount() {
        let QueryClass = this.props.QueryClass
		    console.log("UpdatePhoto_QueryClass///", QueryClass[0])
        let value = JSON.parse(QueryClass[0].value)

        //设置默认选定
        this.setState({
            cla_id: value.cla_id,
            class_id: value.class_id,
            QueryClass
        })
  	}


    //选择Select
	SelectChange(e){
		let select = e.target.value
			console.log("SelectChange//", select)
		let value = JSON.parse(select)

		this.setState({
            cla_id: value.cla_id,
            class_id: value.class_id
        })
    }
    

    //上传状态
    OnProgress(progressEvent, indx){
        let that = this
        //上传进度
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log("上传进度:", percentCompleted);

        //设置进度
        let demoFiles = this.state.demoFiles
            demoFiles[indx].status = percentCompleted + "%"
            that.setState({
                demoFiles
            })
    }


	//上传图片到云存储
	OnUpdatePhoto(){
		/*
		let filePath = this.dataURLtoFile(that.state.demoFiles[0].url, '001.jpg')
		console.log(filePath)
        */

        let that = this
        let demoFiles = this.state.demoFiles

        if(!demoFiles.length){ return null }

        let promiseArr = []
		for(var i = 0; i< demoFiles.length; i++){
            promiseArr.push(new Promise((reslove, reject) => {
    
                //循环上传
                let file = demoFiles[i]
                let indx = i
                that.props.app.uploadFile({
                    cloudPath: that.state.class_id + '/' + (new Date()).valueOf() + "-" + file.name,
                    filePath: file.file.nativeFile,
                    onUploadProgress: (progressEvent)=>{
                        that.OnProgress(progressEvent, indx)
                    }
                })
                .then((res) => {
                    // 上传结果
                    console.log('循环上传:', res)
                    reslove(res.fileID)
                })
                .catch((e) => {
                    console.log('循环上传:', e)
                });


            }));
        }
        
        Promise.all(promiseArr).then(res => {
            //等数组都做完后做then方法
            console.log("图片上传完成后再执行//", res)
            that.add_ClassPhoto(res)
        })

	}



    //记录TCB数据库
    add_ClassPhoto(fileID){
        let that = this

        const db = global.tcb.app.database()
        const comm = db.command
        const coll = db.collection("Hanzi_class")
            coll.doc(that.state.cla_id)
            .update({
                class_photo: comm.push(fileID)
            })
            .then((res) => {
                console.log('记录TCB数据库:', res)
            })
            .catch((e) => {
                console.log('记录TCB数据库:', e)
            });
    }



    renderGallery(){
        if(!this.state.gallery) return false;
        let srcs = this.state.demoFiles.map(file=>file.url)

        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={this.state.gallery.id}
                onClick={ e=> {
                    //avoid click background item
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({gallery: false})
                }}
            >

                <GalleryDelete onClick={ (e, id)=> {
                    this.setState({
                        demoFiles: this.state.demoFiles.filter((e,i)=>i != id),
                        gallery: this.state.demoFiles.length <= 1 ? true : false
                    })
                }} />

            </Gallery>
        )
	}
	


	render() {
		return (
            <Page className="cell" title="Uploader" subTitle="上传组件，一般配合Gallery使用">
                <CellsTitle>选择年级</CellsTitle>
				<SelectClassID data={this.state.QueryClass} Change={this.SelectChange}></SelectClassID>


                { this.renderGallery() }
                <Form>
                    <Cell>
                        <CellBody>
                            <Uploader
                                title="上传课文照片"
                                maxCount={8}
                                files={this.state.demoFiles}
                                onError={msg => alert(msg)}
                                onChange={(file,e) => {
									let newFiles = [
										...this.state.demoFiles, 
										{
											url: file.data,
											name: file.name,
											file: file
										}
									];
                                    this.setState({
                                        demoFiles: newFiles
									});
									//console.log("onchange", file, e)
								}}
								
                                onFileClick={
                                    (e, file, i) => {
                                        console.log('file click', file, i)
                                        this.setState({
                                            gallery: {
                                                url: file.url,
                                                id: i
                                            }
                                        })
                                    }
                                }
                                lang={{
                                    maxError: maxCount => `Max ${maxCount} images allow`
                                }}
                            />
                        </CellBody>
                    </Cell>
                </Form>


				<ButtonArea>
					<Button onClick={this.OnUpdatePhoto}>上传照片</Button>
				</ButtonArea>
            </Page>
		);
	}
}