import React from 'react';

import UpdateClass from './UpdateClass';
import UpdateForm from './UpdateForm';
import UpdatePhoto from './UpdatePhoto';

import { 
    Tab,
    TabBody,
    NavBar,
    NavBarItem,
	Article,
} from 'react-weui';



export default class SetHanzi extends React.Component {
	constructor(props) {
        super(props);
        
		this.state = {
			tab: 0,
			QueryClass: null
		};
	}


	//页面初始化
	componentDidMount() {
		//初始化查询
		this.QueryClass()
    }
    
    
    //查询班级类别
	QueryClass(){
		let that = this
		//TCB云数据库查询
		var coll = global.tcb.app.database().collection("Hanzi_class")
			coll.where({}).get()
			.then((res) => {
				console.log('TCB云数据库查询:', res)

				if(res.data.length){
                    console.log('查询成功:', res.data.length)
					
					//组装SelectClass数组
					that.ArrSelectClass(res.data)
                    
				}
			})
  			.catch((e) => {
				console.log('TCB云数据库查询:', e)
			});

			
		/*
		let url = 'https://pay.doubia.cn/hanzi_QueryClass',
			data = {}

		post(url, data).then((res)=>{

			that.setState({showLoading: false})
			console.log(res)

			if(res.code == 200){
				console.log('查询成功:', res.msg)

				that.setState({
					ClassID: res.data[0].value
				})

				//组装SelectClass数组
				that.ArrSelectClass(res.data)
			}
		}).catch((err)=>{
			console.log(err)
		})
		*/

	}
	

	//组装SelectClass数组
	ArrSelectClass(data){
		let ArrClass = []
		data.map(item =>{

			let value = {
				cla_id: item._id,
				class_id: item.class_id
			}
			let key = {
				value: JSON.stringify(value),
				label: item.class
			}
			ArrClass.push(key)
		})

		this.setState({
			QueryClass: ArrClass
		})

		console.log("ArrClass//", ArrClass)
	}

    


    render() {
        return (
            <Tab>
                <NavBar>
                    <NavBarItem active={this.state.tab == 0} onClick={e=>this.setState({tab:0})}>年级</NavBarItem>
                    <NavBarItem active={this.state.tab == 1} onClick={e=>this.setState({tab:1})}>汉字</NavBarItem>
                    <NavBarItem active={this.state.tab == 2} onClick={e=>this.setState({tab:2})}>课文</NavBarItem>
                </NavBar>
                <TabBody>
                    <Article style={{display: this.state.tab == 0 ? null : 'none'}}>
						<UpdateClass name={global.tcb.name} app={global.tcb.app} />
                    </Article>
                    <Article style={{display: this.state.tab == 1 ? null : 'none'}}>
						{this.state.QueryClass ? <UpdateForm QueryClass={this.state.QueryClass} app={global.tcb.app} /> : null}
                    </Article>
                    <Article style={{display: this.state.tab == 2 ? null : 'none'}}>
                        {this.state.QueryClass ? <UpdatePhoto QueryClass={this.state.QueryClass} app={global.tcb.app} /> : null}
                    </Article>
                </TabBody>
            </Tab>
        );
    }

}



