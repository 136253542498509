import React from 'react';
/*import logo from './logo.svg';*/
import './App.css';


import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

/* 路由页面 */
import HanziClass from './HanziClass';
import ToHanzi from './toHanzi';



/**
 * 路由整合/父组件
 */
export default class App extends React.Component {

	//页面状态数据（类似this.data）
	constructor(props) {
		super(props);
		this.state = {}
	}


	//页面初始化
	componentDidMount() {
		
	}


	render() {
		return (
			<Router className="App">
				<Switch>
					<Route exact path="/" component={HanziClass}></Route>
					<Route path="/ToHanzi" component={ToHanzi}></Route>
				</Switch>
			</Router>
		);
	}
   
}

