import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import TabBar from './TabBar';
import 'weui';

//全局TCB登录
import './global';
import * as serviceWorker from './serviceWorker';

/**
 * React.StrictMode (严格模式下)
 */
ReactDOM.render(
  /*
  <React.StrictMode>
    <TabBar />
  </React.StrictMode>
  */
  <TabBar />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
